body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--font-color);
    /* font-family: 'Passion One', cursive; */
}

.container {
    /* margin: 0 1em; */
    max-width: var(--ifm-container-width);
    height: calc(100% - 4em);
    overflow: scroll;
}

:root {
    --color-emphasis: #0969da;
    --color-light-emphasis: #B5D1F3;
    --color-middle-emphasis: #5396E4;
    --color-not-ok: #d84f2a;
    --color-light: #f6f8fa;
    --color-ok: #1f883d;
    --color-ok-font: #ffffff;
    --color-darker: #d0d7de;
    --ifm-container-width: 800px;
    --font-color: #495057;
}

.input-container {
    width: 100%;
    position: relative;
    padding-bottom: 1em;
}

.input-field {
    width: calc(100% - 1.5rem);
    height: 2.3em;
    padding: .5em .75em;
    font-size: 1rem;
    border-radius: .25em;
    font-weight: bold;
    border: 1px solid var(--color-darker);
    color: var(--font-color);
}

.input-label {
    position: absolute;
    top: -0.6em;
    left: 1em;
    background: white;
    font-size: 0.7em;
}

.input-icon {
    position: absolute;
    z-index: 1;
    right: 1em;
    top: 1.15em;
}

.input-icon-ok {
    color: var(--color-emphasis)
}

.input-icon-not-ok {
    color: var(--color-not-ok)
}

.button {
    padding: 0.8em 0.7em;
    border-radius: 0.25em;
    margin-bottom: 0.5em;
    font-weight: bold;
    background: var(--color-ok);
    color: var(--color-ok-font);
    border: 0.1em solid var(--font-color);
    width: 50%;
    /* margin-left: calc(44% - 2px); */
    text-align: center;
    text-transform: uppercase;
    height: 1em;
}
.button-container-right {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.delete-button {
    padding: 0.8em 0.7em;
    border-radius: 0.25em;
    margin-bottom: 0.5em;
    font-weight: bold;
    background: var(--color-not-ok);
    color: var(--color-ok-font);
    border: 0.1em solid var(--font-color);
    width: 20%;
    text-align: center;
    text-transform: uppercase;
    height: 1em;
}

.dialog-button {
    padding: 0.8em 0.7em;
    border-radius: 0.25em;
    margin-bottom: 0.5em;
    font-weight: bold;
    border: 0.1em solid var(--font-color);
    width: 50%;
    text-align: center;
    text-transform: uppercase;
    height: 1em;
}

.stats-table {
    text-align: center;
    table-layout: fixed;
}

.table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1em;
}

.table td {
    padding: 0.7em 0em;
    border-bottom: 0.1em solid var(--color-light);
}

.table thead {
    font-weight: bold;
}

.table>thead>tr>td {
    border-bottom: 0.1em solid black;
}

.table-sumline {
    border-top: 0.15em solid var(--color-middle-emphasis);
}

.section-container {
    border-radius: 0.15em;
    box-shadow: 0px 0px 0px 1px var(--color-darker), 0px 2px 0px 0px var(--color-darker);
    border-top: 0.2em solid var(--color-middle-emphasis);
    padding: 1em;
    width: calc(100% - 2.1em);
    margin-left: 1px;
    margin-bottom: 1em;
}

.header-1 {
    font-size: 1.2em;
    font-weight: bold;
    padding-bottom: 0.5em;
}

.list-item {
    font-size: 1.1em;
    padding: 0.7em 0.7em;
    box-shadow: 0px 0px 0px 1px var(--color-darker), 0px 2px 0px 0px var(--color-darker);
    border-bottom: 0.15em solid;
    border-radius: 0.25em;
    margin-bottom: 0.5em;
}

.list-item-icon {
    width: 1.5em;
    padding-right: 0.5em;
    color: var(--color-middle-emphasis);
}

.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    font-size: 0.85em;
    justify-content: space-evenly;
    align-items: center;
    height: 6em;
    background-color: var(--color-light);
    max-width: calc(var(--ifm-container-width) + 1em + 2px);
}

.footer-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;
    border-radius: 0.25em;
}

.footer-icon {
    color: var(--color-middle-emphasis);
    height: 2em;
    padding: 0.5em 1em;
    border-radius: 0.25em;
}

.number-selection-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5em;
}

.number-selection {
    padding: 0.7em 1.2em;
    font-size: 1.2em;
    background-color: var(--color-light);
    border: 1px solid var(--color-emphasis);
    border-radius: 0.25em;
}

h2 {
    padding-left: 0.7em;
    color: var(--font-color);
    text-transform: uppercase;
}

.main-content {
    display: block;
    width: 100%;
    height: calc(100% - 2em);
    padding: 1em 0em;
    position: absolute;
}

.warband-control {
    padding: 0.7em 0.7em;
    font-size: 1.1em;
    box-shadow: 0px 0px 0px 1px var(--color-darker), 0px 2px 0px 0px var(--color-darker);
    border-radius: 0.25em;
    margin-bottom: 0.5em;
}

.warband-control-icon {
    width: 1.5em
}

.content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5em;
}

.modal {
    position: absolute;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none;
    height: 90%;
}

.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: calc(100% - 1em);
    padding: 0.5em;
    pointer-events: auto;
    background-color: var(--color-ok-font);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    outline: 0;
    max-width: 500px;
    margin: 1.75em auto;
    height: calc(100% - 5em);
    overflow-y: scroll;
}

.modal-input {
    display: block;
    width: calc(100% - 2.5rem);
    height: calc(2.25em + 2px);
    padding: .375em .75em;
    margin: 0.5em;
    font-size: 1rem;
    font-weight: 400;
    color: var(--font-color);
    background-color: var(--color-ok-font);
    background-clip: padding-box;
    border: 1px solid var(--color-ok-font);
    border-radius: .25em;
}

option:disabled {
    color: var(--color-light);
}

.equipment-section {
    width: 100%
}

.equipment-section-table {
    margin: 0 0 1em 0;
}

.equipment-section-table>tbody>tr>td {
    text-align: center;
}

.equipment-section-table>thead>tr {
    font-weight: 600;
}

.equipment-section-table>thead>tr>td {
    text-align: center;
}

.equipment-section-table>thead>tr>td:first-child {
    text-align: left;
}

.equipment-section-table>tbody>tr>td:first-child {
    text-align: left;
}

.weapon-maintain-command-icon {
    padding: 0 0 0 1em;
    height: 1.3em;
}

.pdf-container {
    width: 29.7cm;
    height: 21cm;
    margin: 10mm;
    display: flex;
    padding: 10mm;
    font-size: 12px;
    position: absolute;
    top: -10000px
}

.tooltip {
    font-size: 0.9em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    color: var(--color-emphasis)
}

.dialog-warrior-sheet {
    padding-bottom: 2em;
    display: flex;
    flex-direction: column;
    font-size: 1.1em;
    border-bottom: 0.15em solid var(--color-middle-emphasis);
}

.warrior-name-container {
    margin-bottom: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.warrior-name {
    font-weight: 700;
    display: flex;
    flex-direction: column;
    font-size: 1.2em;
}

.warrior-card-edit {
    height: 1.2em;
}

.warrior-cost {
    border-radius: 0.25em;
    display: flex;
    align-items: center;
    font-size: 1.4em;
    font-weight: bold;
}

.warrior-type {
    font-size: 0.75em;
    font-weight: normal;
}

.equipment-text {
    font-size: 0.65em;
    font-weight: normal;
}

.warrior-list-section {
    width: 100%;
    margin-bottom: 0.1em;
    background-color: transparent;
    display: table;
    table-layout: fixed;
    border-collapse: collapse;
    font-size: 0.9em;
}

.warrior-list-section>tbody>tr>td {
    padding: .3rem;
    vertical-align: top;
}

.warrior-list-section td:first-child {
    width: 25%;
    font-weight: bold;
    border-right: 2px solid var(--color-middle-emphasis);
}

.warrior-list-section td:nth-child(2) {
    padding-left: 1em;
}

.warrior-list-section tr:last-child {
    margin-bottom: 2em;
}

.check-icon {
    color: #40826D;
    font-size: 2.5em;
}

.message-toast {
    position: absolute;
    bottom: 4em;
    width: calc(100% - 5.5em) !important;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background: #dee2e6;
    height: 3em;
    border-left: 1em solid #a05236;
    border-bottom: 3px solid #a05236;
    font-weight: bold;
}

.name-info-text {
    padding: .0em .75em;
    margin: 0.5em;
    font-size: 0.75em;
    /* color: #d8dfed; */
    color: var(--font-color)
}

.warrior-name-info-text {
    padding: .0em .75em;
    margin: 0.5em;
    font-size: 0.75em;
    color: #324a5f;
}

.input-dropdown {
    width: 100%;
    height: 3.5em;
    /* border-left: solid 1em #40826D;
    border-bottom: solid 3px #40826D; */
    /* border-left: solid 1em var(--ok-color);
    border-bottom: solid 3px var(--ok-color); */
}

.input {
    padding: .375em .75em;
    font-size: 1rem;
    border-radius: .25em;
    font-weight: bold;
    color: #495057;
    border-top: 1px solid #ced4da;
    border-right: 1px solid #ced4da;
    border-left: 1px solid #ced4da;
}

.disabled {
    opacity: 0.3;
}

.warrior-table {
    width: 100%;
    margin-bottom: 0.1em;
    margin-left: 0.5em;
    margin: 0.3em 0em;
    table-layout: fixed;
    border-collapse: collapse;
    font-size: 0.8em;
}

.warrior-stats-table {
    table-layout: fixed;
    border-collapse: collapse;
    margin-bottom: 1em;
}


.warrior-stats-table td,
.warrior-stats-table th {
    padding: .5rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    text-align: center;
    font-size: 0.85em;
}


.create-button {
    /* background-color: #e3a453;
    color: #324a5f; */
    /* -webkit-box-shadow:0px 1px 1px var(--not-ok-color);
 -moz-box-shadow:0px 1px 1px var(--not-ok-color); */
    box-shadow: 0.2em 0.2em 0.2em var(--ok-color);
    height: 2em;
    width: 50%;
    margin-left: 40%;
    padding: .5em 1em;
    margin-top: 1em;
    font-weight: 700;
    border-radius: .25em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.25em;
    position: relative;
    background-color: white;
}

.modern-container {
    background: white;
}

.modern-table {
    border-collapse: collapse;
    width: 100%;
    display: table-column-group;
}

.modern-table thead {
    font-weight: bold;
}

.modern-table tbody tr:first-child td {
    border-top: 1px solid var(--background-color);
}

.modern-table td {
    padding: 0.7em;
}

.tabstrip {
    font-size: 1.2em;
    font-weight: bold;
    display: flex;
    width: 100%;
    border-bottom: 1px solid var(--color-middle-emphasis);
}

.tab {
    padding: 0.7em
}

.focused {
    background-color: var(--color-light-emphasis) !important;
}

.warrior-hero-icon {
    position: absolute;
    left: 0.7em;
    bottom: 0.7em;
    height: 1.5em;
    align-content: center;
    color: var(--color-middle-emphasis);
}

.warrior-card {
    background: white;
    padding: 0.7em 0.7em 1.7em 0.7em;
    border-bottom: 0.15em solid;
    box-shadow: 0px 0px 0px 1px var(--color-darker), 0px 2px 0px 0px var(--color-darker);
    border-radius: 0.25em;
    margin-bottom: 1em;
    position: relative;
}

.stats-maintain-table {
    margin: 0em 0.5em;
    width: calc(100% - 1em);
}

.stats-maintain-table td {
    padding: 0.3em 0.75em;
}

.stats-icon {
    height: 2em;
    color: color-mix(in srgb, var(--color-emphasis) 50%, white) !important;
}

.equipment-table>tbody>tr>td:first-child {
    text-align: left;
}

.equipment-table>thead>tr>td:first-child {
    text-align: left;
}

.equipment-table {
    text-align: center;
}

.equipment-icon {
    height: 2em;
    color: color-mix(in srgb, var(--color-emphasis) 50%, white);
}

.equipment-purchase-more {
    padding-left: 1em;
    float: left;
    padding-top: 0.15em;
}

.rule-container {
    display: flex;
    justify-content: left;
    padding-bottom: 0.5em;
}

.rule-token {
    background-color: var(--color-middle-emphasis);
    color: var(--color-ok-font);
    font-weight: bold;
    padding: 0.5em 0.5em;
    border-radius: 0.25em;
    margin-right: 0.5em
}

.control-group {
    width: 43%;
    background: color-mix(in srgb, var(--color-darker) 50%, white);
    margin: 0em 0.2em 0.5em;
    padding: 0.5em;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 0.5em;
}

.kpi-entry {
    width: 45%;
    margin: 0.2em 0.5em;
    padding: 0.5em;
    display: flex;
    justify-content: space-evenly;
    border-radius: 0.5em;
    flex-direction: column;
}

.control-container {
    width: 100%;
    display: flex;
    font-size: 0.89em;
    max-width: calc(var(--ifm-container-width) + 1em + 2px);
    flex-wrap: wrap;
}

.dropdown-box {
    width: calc(100% + 1px);
    height: 3.5em;
    padding: .375em .75em;
    font-size: 1rem;
    border-radius: .25em;
    font-weight: bold;
    color: var(--font-color);
    border: 1px solid var(--color-emphasis);
    appearance: none;
}

.overview-edit-icon {
    color: var(--color-middle-emphasis);
    padding: 0.5em 1em 0em 0.5em;
}

.warrior-header-icons-container {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.skill-selection-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5em;
    width: 100%;
    flex-direction: column;
}

.skill-selection-header {
    font-weight: bold;
    font-size: 1em;
    padding: 0.5em;
}
.skill-selection-item {
    font-size: 0.85em;
    padding: 0.5em;
}

.skill-selection-item-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.1em solid color-mix(in srgb, var(--color-emphasis) 50%, white);
}

.skill-selection-icon {
    /* color: color-mix(in srgb, var(--color-emphasis) 50%, white); */
    padding: 1em;
    height: 1em;
}

.equipment-selection-item-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-bottom: 0.1em solid color-mix(in srgb, var(--color-emphasis) 50%, white); */
    position: relative;
}

.equi-input-header-icon {
    position: absolute;
    z-index: 1;
    right: 1em;
    top: 0.7em;
}


.equi-input-textfield {
    position: absolute;
    z-index: 1;
    right: 3em;
    top: 1em;
    width: 3em;
    font-weight: bold;
    color: var(--color-ok);
    text-align: end;
}

.new-pdf-unit-card {
    font-size: 12px;
    width: 13.8cm;
    padding: 10px;
    margin: 3px;
    page-break-inside: avoid;
    display: inline-block;
    position: relative;
    border-bottom: 1px solid;
}

.new-pdf-weapon-table {
    width: 100%;
    text-align: left;
    padding-bottom: 0.5em;
}

.pdf-text {
    font-size: 1em;
    padding: 0em 0.5em 0.5em 0.5em;
}

.warband-kpi {
    width: 100%;
     display: flex;
     justify-content: center;
     height: 4em; 
     max-width: calc(var(--ifm-container-width) + 1em + 2px);
}